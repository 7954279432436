<template>
	<div>
		<div class="payment-main">
			<div class="breadcrumb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><span style="cursor: pointer" @click="$router.push({ path: '/' })">首页</span></el-breadcrumb-item>
					<el-breadcrumb-item>支付页面</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="payment-top">
				<div class="payment-top-title">
					<i class="iconfont icontijiao-"></i>
					订单提交成功，现在只差最后一步啦！
				</div>
				<div class="payment-top-bottom">
					<div class="payment-top-date">
						您还有
						<span>{{ time }}</span>
						来付款,超时订单将自动关闭
					</div>
					<div></div>
				</div>
				<div class="border"></div>
				<div class="payment-list">
					<div class="payment-left m-t-10">应付总额：</div>
					<div class="payment-price">￥{{ order.TotalPrice }}</div>
				</div>
				<div class="payment-list">
					<div class="payment-left">收货地址：</div>
					<div class="payment-addr">{{ order.Province }}{{ order.City }}{{ order.District }} {{ order.Address }} {{ order.UserName }} {{ order.UserPhone }}</div>
				</div>
				<div class="payment-list">
					<div class="payment-left">订单号：</div>
					<div>
						{{ order.OrderID }}
						<span class="more" @click="viewMore">查看更多</span>
					</div>
				</div>
				<div class="payment-list">
					<div class="payment-left">发票信息：</div>
					<div>
						<div v-if="order.InvoiceType == 2 || order.InvoiceType == 3" class="payment-list-button">
							增值税普通发票
							<span></span>
						</div>
						<div v-if="order.InvoiceType == 1" class="payment-list-button">增值税专用发票</div>
						<div v-if="order.InvoiceType == 3" class="payment-list-info">个人</div>
						<div v-if="order.InvoiceType == 2 || order.InvoiceType == 1" class="payment-list-info">
							<span>单位</span>
							<span>{{ order.InvoiceTitle }}</span>
							<span>{{ order.DutyParagraph }}</span>
							<span>商品明细</span>
						</div>
						<div></div>
					</div>
				</div>
			</div>
			<div class="pay-title">- 请选择支付方式</div>
			<div class="pay-type">
				<div class="active" @click="changePay(item, index)" v-for="(item, index) in payList" :key="index">
					<img :src="item.pic" alt="" />
					<div class="active-img" v-if="item.active"><img src="../../assets/invoice.png" alt="" /></div>
				</div>
			</div>
			<div class="pay-pic" v-show="payList[0].active">
				<div>
					<div class="pay-pic-title">推荐扫码支付：</div>
					<div class="pay-left">
						<div><div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div></div>
						<div class="pay-left-text">
							<div>打开手机支付宝</div>
							<div>扫一扫继续付款</div>
						</div>
					</div>
				</div>
				<div class="pay-right">
					<!-- 支付成功请点击
                    <div class="button">
                        已扫码付款
                    </div> -->
					<div class="pay-right-bottom">
						如有疑问或需要帮助，请联系
						<el-button size="mini" @click="toChat(order)">在线客服</el-button>
					</div>
				</div>
			</div>
			<div class="pay-pic" v-show="payList[1].active">
				<div>
					<div class="pay-pic-title">推荐扫码支付：</div>
					<div class="pay-left">
						<div><div class="qrcode" id="qrCodeUrl1" ref="qrCodeUrl1"></div></div>
						<div class="pay-left-text">
							<div>打开手机微信</div>
							<div>扫一扫继续付款</div>
						</div>
					</div>
				</div>
				<div class="pay-right">
					<!-- 支付成功请点击
                    <div class="button">
                        已扫码付款
                    </div> -->
					<div class="pay-right-bottom">
						如有疑问或需要帮助，请联系
						<el-button size="mini" @click="toChat(order)">在线客服</el-button>
					</div>
				</div>
			</div>
			<div v-if="payList[2].active">
				<div class="enterprise">
					<div class="enterprise-left">
						<div>
							您的汇款识别码
							<span
								style="
color: #DC2310;"
							>
								{{ enterpriseList.PayCode }}
							</span>
							汇款填写用途/备注/摘要栏，便于快速核销款项。( 区分大小写 )
						</div>
						<div class="enterprise-info">转账银行信息：</div>
						<div
							class="enterprise-item"
							style="
background: rgba(0, 181, 194, 0.1);"
						>
							<div class="enterprise-item-left">收款人户名</div>
							<div class="enterprise-item-right enterprise-title">{{ enterpriseList.HuName }}</div>
						</div>
						<div class="enterprise-item">
							<div class="enterprise-item-left">银行卡号</div>
							<div class="enterprise-item-right">{{ enterpriseList.CardNumber }}</div>
						</div>
						<div class="enterprise-item">
							<div class="enterprise-item-left">开户银行</div>
							<div class="enterprise-item-right">{{ enterpriseList.CardName }}</div>
						</div>
						<div class="enterprise-item">
							<div class="enterprise-item-left">银联号(非必填）</div>
							<div class="enterprise-item-right">{{ enterpriseList.UnionPayCode }}</div>
						</div>
						<div class="enterprise-item">
							<div class="enterprise-item-left">用途/备注/摘要栏</div>
							<div class="enterprise-item-right">{{ enterpriseList.PayCode }}</div>
						</div>
					</div>
					<div class="enterprise-right">
						<div class="title">注意事项</div>
						<div>1、下单后请尽快转账，务必保证转账金额与订单金额一致，请勿多转、少转和分次转账，否则影响订单对账进度，7天内未对账系统自动取消订单；</div>
						<div>2、汇款时将汇款识别码填写至汇款单“用途/备注/摘要”等栏；</div>
						<div>
							3、如汇款出现特殊情况，请提供订单号及正规的汇款底单（汇款底单需包含收付款户名，收付款账号，公章等，如存在代汇款、汇款后委托他人或企业用款等情况，还需提供对应的代付/委托声明）咨询客服处理。
						</div>
					</div>
				</div>
			</div>
			<div class="download" v-if="payList[2].active" v-print="printObj">下载采购供单</div>
		</div>
		<div class="sss">
			<div id="printTest" v-if="wordInfo">
				<div><img src="http://haike.365dajiankang.com/img/logo.png" alt="" /></div>
				<div class="title">海克斯康商城订单支付信息</div>
				<div>
					<table border="1">
						<tr>
							<td width="500">公司名称</td>
							<td width="500">{{ wordInfo.BankData.HuName }}</td>
						</tr>
						<tr>
							<td width="500">银行卡号</td>
							<td width="500">{{ wordInfo.BankData.CardNumber }}</td>
						</tr>
						<tr>
							<td width="500">开户银行</td>
							<td width="500">{{ wordInfo.BankData.CardName }}</td>
						</tr>
						<tr>
							<td width="500">银联号(非必填)</td>
							<td width="500">{{ wordInfo.BankData.UnionPayCode }}</td>
						</tr>
						<tr>
							<td width="500">汇款识别码（必填）</td>
							<td width="500">{{ wordInfo.PayCode }}</td>
						</tr>
						<tr>
							<td width="200">备注</td>
							<td width="800">请在备注栏填写正确的识别码；如果识别码错误或缺失，可能导致发货延迟。 请在备注栏填写正确的识别码；如果识别码错误或缺失，可能导致发货延迟。</td>
						</tr>
						<tr style="text-align:center"><td width="200" colspan="2">订单信息</td></tr>
					</table>

					<table border="1">
						<tr>
							<td width="50">序号</td>
							<td width="400">产品描述</td>
							<td width="100">参数</td>
							<td width="50">数量</td>
							<td width="50">金额（元)</td>
						</tr>
						<tr v-for="(item, index) in wordInfo.Detail" :key="index">
							<td width="50">{{ index + 1 }}</td>
							<td width="350">{{ item.detail.ProName }}</td>
							<td width="100">
								<div class="canshu" v-if="item.ConList.length > 0">{{ item.ConList[0].CName }}</div>
							</td>
							<td width="50">{{ item.detail.PCount }}</td>
							<td width="50">{{ item.detail.LastPrice }}</td>
						</tr>
						<tr>
							<td width="200" colspan="4">
								合计
								<span>{{ wordInfo.TotalPrice | toChies }}</span>
							</td>
							<td width="200">{{ wordInfo.TotalPrice }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { aliPay, getOrderInfo, getCorporateInfo, WeChatPay, getOrderWordInfo } from './service';

export default {
	name: 'index',
	filters: {
		//局部过滤器
		toChies: function(amount) {
			//形参
			// 汉字的数字
			const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
			// 基本单位
			const cnIntRadice = ['', '拾', '佰', '仟'];
			// 对应整数部分扩展单位
			const cnIntUnits = ['', '万', '亿', '兆'];
			// 对应小数部分单位
			const cnDecUnits = ['角', '分'];
			// 整数金额时后面跟的字符
			const cnInteger = '整';
			// 整型完以后的单位
			const cnIntLast = '元';
			// 最大处理的数字
			const maxNum = 9999999999999999.99;
			// 金额整数部分
			let integerNum;
			// 金额小数部分
			let decimalNum;
			// 输出的中文金额字符串
			let chineseStr = '';
			// 分离金额后用的数组，预定义
			let parts;
			if (amount === '') {
				return '';
			}
			amount = parseFloat(amount);
			if (amount >= maxNum) {
				// 超出最大处理数字
				return '';
			}
			if (amount === 0) {
				chineseStr = cnNums[0] + cnIntLast + cnInteger;
				return chineseStr;
			}
			// 转换为字符串
			amount = amount.toString();
			if (amount.indexOf('.') === -1) {
				integerNum = amount;

				decimalNum = '';
			} else {
				parts = amount.split('.');
				integerNum = parts[0];
				decimalNum = parts[1].substr(0, 4);
			}
			// 获取整型部分转换
			if (parseInt(integerNum, 10) > 0) {
				let zeroCount = 0;
				const IntLen = integerNum.length;
				for (let i = 0; i < IntLen; i++) {
					const n = integerNum.substr(i, 1);
					const p = IntLen - i - 1;
					const q = p / 4;
					const m = p % 4;
					if (n === '0') {
						zeroCount++;
					} else {
						if (zeroCount > 0) {
							chineseStr += cnNums[0];
						}
						// 归零
						zeroCount = 0;
						//alert(cnNums[parseInt(n)])
						chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
					}
					if (m === 0 && zeroCount < 4) {
						chineseStr += cnIntUnits[q];
					}
				}
				chineseStr += cnIntLast;
			}
			// 小数部分
			if (decimalNum !== '') {
				const decLen = decimalNum.length;
				for (let i = 0; i < decLen; i++) {
					const n = decimalNum.substr(i, 1);
					if (n !== '0') {
						chineseStr += cnNums[Number(n)] + cnDecUnits[i];
					}
				}
			}
			if (chineseStr === '') {
				chineseStr += cnNums[0] + cnIntLast + cnInteger;
			} else if (decimalNum === '') {
				chineseStr += cnInteger;
			}
			return chineseStr;
		}
	},
	data() {
		return {
			enterpriseList: {},
			wordInfo: '',
			interval: null,
			printObj: {
				id: 'printTest', // 这里是要打印元素的ID
				popTitle: ' ', // 打印的标题
				extraCss: '', // 打印可引入外部的一个 css 文件
				extraHead: '' // 打印头部文字
			},
			payList: [
				{
					pic: require('../../assets/zhifubao.png'),
					type: 2,
					active: true
				},
				{
					pic: require('../../assets/weixin.png'),
					type: 1,
					active: false
				},
				{
					pic: require('../../assets/qiye.png'),
					type: 3,
					active: false
				}
			],
			time: '',
			order: ''
		};
	},
	async mounted() {
		await this.init();
		var dateTime = new Date(this.order.CreatTime);
		dateTime = dateTime.setDate(dateTime.getDate() + 7);
		dateTime = new Date(dateTime);
		setInterval(() => {
			this.showtime(dateTime);
		}, 1000);
	},
	methods: {
		toChat(order) {
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
			// this.ysf('product', {
			// 	show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
			// 	title: '订单号：' + order.OrderID,
			// 	desc:  '收货地址：' + order.Province + order.Province + order.City + order.District + ' ' + order.Address,
			// 	// picture: FILE_URL + order.DetailList[0].ProPictrue,
			// 	note: '实付金额：￥' + order.TotalPrice,
			// 	success: () => {
			// 		// 成功回调
			// 		this.ysf('open');
			// 	},
			// 	error: () => {
			// 		// 错误回调
			// 		this.ysf('open');
			// 	}
			// });
		},
		showtime(data) {
			var nowtime = new Date(), //获取当前时间
				endtime = new Date(data); //定义结束时间
			var lefttime = endtime.getTime() - nowtime.getTime(),
				days = parseInt(lefttime / 1000 / 60 / 60 / 24), //计算剩余的天数//距离结束时间的毫秒数
				lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
				leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
				lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
			if (lefts.length == 1) {
				lefts = '0' + lefts;
			}
			lefth = lefth >= 0 && lefth < 10 ? '0' + lefth : '' + lefth;
			leftm = leftm >= 0 && leftm < 10 ? '0' + leftm : '' + leftm;
			lefts = lefts >= 0 && lefts < 10 ? '0' + lefts : '' + lefts;
			this.time = days + '天' + lefth + '小时' + leftm + '分' + lefts + '秒';
		},
		// 支付切换
		changePay(data, index) {
			clearInterval(this.interval);
			for (let item in this.payList) {
				this.payList[item].active = false;
			}
			this.payList[index].active = true;
			data.active = true;
			document.getElementById('qrCodeUrl').innerHTML = '';
			document.getElementById('qrCodeUrl1').innerHTML = '';
			switch (data.type) {
				case 1:
					WeChatPay({ OrderID: this.order.OrderID }).then(res => {
						new QRCode(this.$refs.qrCodeUrl1, {
							text: res.data.qr_code, // 需要转换为二维码的内容
							width: 100,
							height: 100,
							colorDark: '#000000',
							colorLight: '#ffffff',
							correctLevel: QRCode.CorrectLevel.H
						});
						this.order.OrderID = res.data.OrderID;
						this.$router.push({
							path: `/payment?orderID=${this.order.OrderID}`
						});
					});
					this.interval = setInterval(() => {
						getOrderInfo({ OrderID: this.order.OrderID }).then(res => {
							if (res.data.State == 1) {
								clearInterval(this.interval);
								this.$router.push({
									path: `/me/order`
								});
								this.$message.success('支付成功');
							}
						});
					}, 2000);
					break;
				case 2:
					aliPay({ OrderID: this.order.OrderID }).then(res => {
						new QRCode(this.$refs.qrCodeUrl, {
							text: res.data.qr_code, // 需要转换为二维码的内容
							width: 100,
							height: 100,
							colorDark: '#000000',
							colorLight: '#ffffff',
							correctLevel: QRCode.CorrectLevel.H
						});
						this.order.OrderID = res.data.OrderID;
					});
					this.interval = setInterval(() => {
						getOrderInfo({ OrderID: this.order.OrderID }).then(res => {
							if (res.data.State == 1) {
								clearInterval(this.interval);
								this.$router.push({
									path: `/me/order`
								});
								this.$message.success('支付成功');
							}
						});
					}, 2000);
					break;
				case 3:
					clearInterval(this.interval);
					getCorporateInfo({ OrderID: this.order.OrderID }).then(res => {
						this.enterpriseList = res.data;
						getOrderWordInfo({
							OrderID: this.$route.query.orderID
						}).then(ress => {
							this.wordInfo = ress.data;
							// console.log(ress.data);
						});
					});
					break;
			}
		},
		async init() {
			this.$store.commit('add');

			await getOrderInfo({ OrderID: this.$route.query.orderID }).then(res => {
				this.order = res.data;
				this.order.TotalPrice = this.order.TotalPrice.toFixed(2);
				clearInterval(this.interval);
				aliPay({ OrderID: this.order.OrderID }).then(res => {
					new QRCode(this.$refs.qrCodeUrl, {
						text: res.data.qr_code, // 需要转换为二维码的内容
						width: 100,
						height: 100,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					});
					this.order.OrderID = res.data.OrderID;
					this.$router.push({
						path: `/payment?orderID=${this.order.OrderID}`
					});
				});
				this.interval = setInterval(() => {
					getOrderInfo({ OrderID: this.order.OrderID }).then(res => {
						if (res.data.State == 1) {
							clearInterval(this.interval);
							this.$router.push({
								path: `/me/order`
							});
							this.$message.success('支付成功');
						}
					});
				}, 2000);
			});
		},
		// 查看更多
		viewMore() {
			this.$router.push({
				path: `/me/order`
			});
		}
	}
};
</script>

<style lang="less" scoped>
.download {
	background: #dc2310;
	width: 150px;
	text-align: center;
	color: #ffffff;
	margin-top: 50px;
	padding: 15px 0;
	cursor: pointer;
}
.payment-main {
	width: 1240px;
	margin: 20px auto;

	.sign {
		color: #0097ba;
	}

	.title {
		margin-top: 30px;
		color: #333333;
		font-family: 'ct';
	}

	.payment-top {
		width: 1240px;
		margin-top: 30px;
		padding: 30px;
		box-sizing: border-box;
		background: #fdfdfd;
		border: 1px solid #dcdcdc;

		.payment-top-title {
			font-family: 'ct';

			i {
				font-size: 22px;
				color: #bad97a;
			}
		}

		.payment-top-bottom {
			margin-left: 25px;
		}

		.border {
			height: 1px;
			background: #f4f4f4;
			margin: 20px 0;
		}

		.payment-top-date {
			margin-top: 20px;
			font-size: 12px;

			span {
				color: #e35d51;
				margin: 0 10px;
			}
		}

		.payment-list {
			display: flex;
			margin: 20px 0;
		}

		.payment-addr {
			width: 500px;
		}

		.more {
			margin-left: 20px;
			color: #1b98aa;
			cursor: pointer;
		}

		.m-t-10 {
			margin-top: 10px;
		}

		.payment-left {
			width: 100px;
		}

		.payment-list-button {
			width: fit-content;
			padding: 2px 10px;
			color: #666666;
			border: 1px solid #bfbfbf;
		}

		.payment-list-info {
			margin-top: 20px;
			span {
				margin-right: 20px;
			}
		}

		.payment-price {
			font-family: 'ct';
			font-size: 22px;
			color: #dc2310;
		}
	}
}
.enterprise {
	margin-top: 30px;
	border: 1px solid #dcdcdc;
	padding: 30px;
	display: flex;
	.enterprise-right {
		padding-top: 30px;
		padding-left: 50px;
		div {
			margin: 10px 0;
			color: #666666;
		}
	}
	.enterprise-title {
		width: 300px;
		overflow: hidden;
		display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
		-webkit-line-clamp: 1; //限制在一个块元素显示的文本的行数
		-webkit-box-orient: vertical; //设置或检索伸缩盒
	}
	.enterprise-item {
		width: 500px;
		padding: 10px 10px;
		display: flex;
		border: 1px solid #dcdcdc;
		margin-top: -1px;
		.enterprise-item-left {
			width: 140px;
		}
	}
	.enterprise-info {
		color: #666666;
		margin: 25px 0;
	}
}
.pay-title {
	font-family: 'ct';
	margin: 20px;
}

.pay-pic {
	background: #fdfdfd;
	border: 1px solid #dcdcdc;
	padding: 20px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	.pay-pic-title {
		font-family: 'ct';
		margin-bottom: 20px;
	}

	.pay-left {
		display: flex;
	}

	.pay-left-text {
		text-align: center;
		margin-left: 20px;
		color: #666666;
	}

	.pay-left-date {
		margin-top: 30px;
		font-size: 12px;
		color: #dc2310;
	}
}

.pay-right {
	width: 500px;
	text-align: center;

	.button {
		background: #f2f2f2;
		border: 1px solid #dcdcdc;
		padding: 5px 10px;
		width: fit-content;
		margin: 20px auto;
		cursor: pointer;
	}

	.pay-right-bottom {
		font-size: 12px;
		margin-top: 60px;

		span {
			color: #0097ba;
		}

		button {
			margin-left: 10px;
		}
	}
}

.pay-type {
	display: flex;

	.active {
		position: relative;
		width: 216px;
	}

	.active-img {
		width: 20px;
		height: 20px;
		position: absolute;
		bottom: 24px;
		right: 1px;
	}

	div {
		margin-right: 20px;
		cursor: pointer;
	}
}
.sss {
	position: absolute;
	top: -300%;
}
#printTest {
	width: 1000px;
	.title {
		text-align: center;
		font-size: 20px;
		margin-bottom: 20px;
	}
	table {
		border-collapse: collapse;
		td {
			padding: 10px 20px;
		}
	}
	.canshu {
	}
}
</style>
